import {
  Box,
  Switch,
  Text,
  Stack,
  Input,
  Flex,
  Container
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { setFeatureFlag } from '../../api'
import { CenteredSpinner } from '../../components/CenteredSpinner'
import { IFeatureFlag } from '../../types'
import { useFeatureFlagsAPI } from '../../utils/apiHooks'

interface FFProps {
  flag: IFeatureFlag
}

export const FeatureFlagToggle: React.FC = () => {
  const { data, revalidate } = useFeatureFlagsAPI()

  const [filter, setFilter] = useState('')
  const onToggleFeatureFlag = async (flag: IFeatureFlag) => {
    const newFlag = { ...flag, enabled: !flag.enabled }
    await setFeatureFlag(newFlag)
    await revalidate()
  }

  if (data === null) return <CenteredSpinner />

  const FeatureFlagCard: React.FC<FFProps> = ({ flag }) => {
    return (
      <Box
        maxW={{ base: 'full', md: '290px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        background={flag.enabled ? 'green.100' : 'gray.100'}
        p={5}
      >
        <Box>
          <Flex justifyContent="space-between">
            <Text fontSize="16px" fontWeight={600}>
              {flag.label}
            </Text>
            <Switch
              colorScheme="green"
              id={flag.name}
              isChecked={flag.enabled}
              onChange={() => onToggleFeatureFlag(flag)}
            />
          </Flex>
          <Text fontSize="12px">{flag.name}</Text>
          <Text fontSize={{ base: 'sm' }} textAlign={'left'} maxW={'4xl'}>
            {flag.description}
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box py={3} w="100%" mb="30px">
      <Container as={Stack} maxW={'7xl'} py={10} bg="white">
        <Stack align={'flex-start'}>
          <Text>Search</Text>
          <Stack direction={'row'}>
            <Input
              placeholder={'filter by name or description'}
              bg={'gray.300'}
              border={0}
              onChange={(element) => {
                setFilter(element.target.value)
              }}
            />
          </Stack>
        </Stack>
        <Flex flexWrap="wrap" gridGap={6} justify="left">
          {data
            ?.filter((flag) => {
              let term = flag.name + flag.label + flag.description
              return term.includes(filter)
            })
            .map((flag, i) => {
              return <FeatureFlagCard key={i} flag={flag} />
            })}
        </Flex>
      </Container>
    </Box>
  )
}
