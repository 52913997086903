import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Text,
  useToast,
  Stack,
  Flex,
  Wrap,
  Tag,
  Divider,
  TagLabel,
  Button,
  Spacer
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IDailyFiveContent } from '../../types'
import { ContentDetailBar } from './ContentDetailBar'
import { UserDisplay } from './UserDisplay'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  content?: IDailyFiveContent
  onDelete: () => void
}
export const ContentModal: React.FC<IModalInput> = ({
  isOpen,
  content,
  onClose,
  onDelete
}) => {
  const toast = useToast()

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent minW="450px" borderRadius="6px">
        <ModalHeader pb={5}>Content</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="center" w="100%" h="100%" flexShrink={0} px={4}>
            <Flex
              paddingBottom="50px"
              width={'100%'}
              p={5}
              justifyContent="left"
              flexShrink={0}
            >
              <Stack w="100%">
                <Flex
                  paddingBottom="16px"
                  w="100%"
                  h="375px"
                  justify="center"
                  background={
                    content?.image ? `url("${content?.image}")` : '#dadada'
                  }
                  backgroundSize="cover"
                  backgroundPosition="center"
                  flexShrink={0}
                  alignItems="flex-end"
                ></Flex>
                <Stack marginTop="32px" flexShrink={0}>
                  <Flex>
                    <Text
                      fontSize="xs"
                      color="#255CE1"
                      fontWeight="bold"
                      mb="8px"
                    >
                      {content?.content_type}
                    </Text>
                  </Flex>

                  <Wrap cursor={'pointer'} maxWidth={'inherit'}>
                    {content &&
                      content.interests &&
                      content.interests.map((interest, i) => (
                        <Tag key={i} size={'sm'} variant="outline">
                          <TagLabel>{interest.name}</TagLabel>
                        </Tag>
                      ))}
                  </Wrap>
                  <Text fontSize="s" color="#000000" fontWeight="bold">
                    {content?.title}
                  </Text>
                  <Text fontSize="xs" color="#000000" fontWeight="bold">
                    Posted By
                  </Text>
                  <Flex align="center">
                    <UserDisplay
                      name={content?.author}
                      src={content?.user_profile_image}
                      size="xs"
                      marginRight="8px"
                    />
                    <Text fontSize="xs" color="#4F4F4F" fontWeight="bold">
                      {content?.author}
                    </Text>
                  </Flex>
                  <ContentDetailBar content={content} />
                </Stack>
                <Flex w="100%" flexShrink={0}>
                  <Text whiteSpace="pre-line">{content?.summary}</Text>
                </Flex>
              </Stack>
            </Flex>
          </Flex>
          <ModalFooter>
            <Spacer />
            <Button
              leftIcon={<DeleteIcon />}
              onClick={() => {
                onDelete()
              }}
            >
              Delete Post (cannot be undone)
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
