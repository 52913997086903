import React, { ReactElement, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  IconButton,
  Spacer,
  Stack,
  StackDivider,
  Tag,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { deactivateTeam, useOrganization } from '../../api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faTimes,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { GenericAlertDialog } from '../../components/GenericAlertDialog'
import { routeStrings } from '../../routeStrings'

export const OrganizationDetail: React.FC = () => {
  let { organization_id } = useParams<{
    organization_id: string
    tenant_id: string
  }>()
  const {
    isOpen: isOpenCancelBookingModal,
    onOpen: onOpenCancelBookingModal,
    onClose: onCloseCancelBookingModal
  } = useDisclosure()
  const toast = useToast()
  const history = useHistory()
  const [isCancelling, setIsCancelling] = useState(false)

  const { data: organization, revalidate } = useOrganization(
    Number(organization_id)
  )

  const handleDeactivateTeam = () => {
    if (isCancelling || !organization) return

    setIsCancelling(true)
    deactivateTeam(organization.id)
      .then((res) => {
        toast({ description: 'Team deactivated', status: 'success' })
        setIsCancelling(false)
        onCloseCancelBookingModal()
        revalidate()
      })
      .catch((error) => {
        toast({
          title: 'Unable to deactivate team',
          description: error.response.data,
          status: 'error',
          isClosable: true,
          duration: 5000
        })
        revalidate()
        setIsCancelling(false)
      })
  }

  if (!organization) return null

  return (
    <Container maxW={'6xl'} mb={4} background={'white'} p={5}>
      <Flex>
        <IconButton
          variant="solid"
          colorScheme="gray"
          aria-label="Send email"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => {
            history.replace(
              routeStrings.tenantDetailView(organization.parent_platform_tenant)
            )
          }}
          mr={3}
          mt={2}
        />
        <Box mb={2}>
          <Text fontSize={['md', 'lg', '3xl']} fontWeight="bold">
            {organization.name}
          </Text>
        </Box>
      </Flex>
      {organization.is_deleted && (
        <Tag colorScheme={'red'} size={'md'}>
          Permanently Deactivated
        </Tag>
      )}
      {organization.is_space_admin && (
        <Tag colorScheme={'blue'} size={'md'}>
          Space Admin
        </Tag>
      )}
      <Divider my={3} />
      <Stack spacing={4}>
        <Stack spacing={4} divider={<StackDivider borderColor={'gray.200'} />}>
          <FeatureComponent
            icon={
              organization.is_active_organization ? (
                <FontAwesomeIcon icon={faCheckCircle} size={'1x'} />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size={'1x'}
                  color="black"
                />
              )
            }
            iconBg={
              organization.is_active_organization ? 'green.100' : 'gray.300'
            }
            text={`${
              organization.is_active_organization
                ? 'Active'
                : 'No active memberships'
            }`}
          />

          {organization.offices && (
            <FeatureComponent
              icon={<FontAwesomeIcon icon={faClock} size={'1x'} />}
              iconBg={'green.100'}
              text={`${organization.offices.length} offices`}
            />
          )}

          <FeatureComponent
            icon={<FontAwesomeIcon icon={faUser} size={'1x'} />}
            iconBg={'cyan.100'}
            text={`${organization.members?.length} team members`}
          />
        </Stack>
      </Stack>

      {!organization.is_deleted && !organization.is_space_admin && (
        <Flex p={4}>
          <Spacer />

          <Button
            colorScheme={'red'}
            onClick={() => {
              onOpenCancelBookingModal()
            }}
          >
            Deactivate
          </Button>
        </Flex>
      )}
      <GenericAlertDialog
        onClose={onCloseCancelBookingModal}
        isConfirming={isCancelling}
        isOpen={isOpenCancelBookingModal}
        title={'Deactivate team'}
        description={'Are you sure you want to deactivate this team?'}
        confirmButtonText={'Confirm'}
        onConfirm={handleDeactivateTeam}
      ></GenericAlertDialog>
    </Container>
  )
}

interface FeatureProps {
  text: string
  iconBg: string
  icon?: ReactElement
}

export const FeatureComponent = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={[100, 100, 600]}>{text}</Text>
    </Stack>
  )
}
