import { Box, Link, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Table, TableDataProp } from '../../components/table/Table'
import { routeStrings } from '../../routeStrings'
import { IOrganization } from '../../types'

interface Props {
  orgs: IOrganization[] | undefined
}

export const TeamsListComponent: React.FC<Props> = ({ orgs }) => {
  const history = useHistory()
  const toast = useToast()
  const [selectedTeam, setSelectedTeam] = useState<IOrganization>()

  function TeamLink(org: IOrganization) {
    if (org) {
      return (
        <Link
          color="#255CE1"
          mb={0}
          onClick={() => {
            history.push(routeStrings.teamDetailView(1, org.id))
          }}
        >
          {org.name}
        </Link>
      )
    }
  }

  const orgsFiltered = orgs
  const tableData =
    (orgsFiltered &&
      orgsFiltered.map((org) => {
        const cleanedData = {
          id: TableDataProp(org.id),
          test_prefix: TableDataProp(org.name),
          // rowData: TableDataProp(org),
          name: TableDataProp(TeamLink(org) || '', org.name),
          home_location: TableDataProp(
            org.home_location ? org.home_location.name : '',
            org.home_location ? org.home_location.name : ''
          ),
          // org.name, `/admin/dashboard/teams/${org.id}`),
          // size: TableDataProp(sizeString(org.seats, org.members)),
          // members: TableDataProp(<UserDisplayGroup users={org.members} />),
          eft_permitted: TableDataProp('NEL'),
          actions: TableDataProp([])
        }

        return cleanedData
      })) ||
    []

  return (
    <Box my="3" w="100%">
      <Table
        pageSize={900}
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },
          {
            header: 'Name',
            accessor: 'name',
            show: true,
            width: 4 / 12,
            enableFilter: false
          },
          {
            header: 'Default Location',
            accessor: 'home_location',
            show: true,
            width: 2 / 12,
            enableFilter: false
          },

          { header: '', accessor: 'actions', show: true, width: 1 / 12 }
          // { header: '', accessor: 'spacer', show: true, width: 3 / 12 }
        ]}
        data={tableData}
        loading={orgs === undefined}
      />
    </Box>
  )
}
