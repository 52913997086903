import useSWR, { ConfigInterface } from 'swr'
import {
  IDailyFiveContent,
  IFeatureFlag,
  IPaginatedDailyFive,
  IPlatformTenant
} from '../types'

export const useTenants = () => {
  return useSWR<IPlatformTenant[]>(`/api/v1/platform-admin/tenants/`)
}

export const useTenantInfo = (tenant_id?: number) => {
  return useSWR<IPlatformTenant>(
    tenant_id ? `/api/v1/platform-admin/tenants/${tenant_id}/` : null,
    {
      revalidateOnFocus: false,
      refreshInterval: 0
    }
  )
}

export const usePublicContentPool = (page: number) => {
  return useSWR<IPaginatedDailyFive>(
    `/api/v1/platform-admin/content/?page=${page}`
  )
}

export function useFeatureFlagsAPI() {
  return useSWR<IFeatureFlag[]>('/api/v1/platform-admin/feature-flags/')
}
