import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getAuthorization, login } from '../api'

import { createCtx } from './createCtx'

export interface AuthContextProps {
  isAuthenticated?: boolean
  loading: boolean
  token?: string
  loginUser?: (email: string, password: string) => Promise<void>
  logoutUser?: (...p: any[]) => void
}

const [useAuth, AuthContextProvider] = createCtx<AuthContextProps>()

/**
 *  Checks if the user is staff or member and sends them to the correct page
 */
export const redirectAfterLoginUrl = () => {
  const params = new URLSearchParams(document.location.search.substring(1))
  const next = params.get('next')
  if (next) {
    return next
  } else {
    return '/dashboard/'
  }
}

const AuthProvider = ({ children }) => {
  const history = useHistory()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()

  const [token, setToken] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)

  const getAndSetAdminAuthorization = async () => {
    // verify authorization
    // set authenticated value
    try {
      const authResponse = await getAuthorization()
      setIsAuthenticated(authResponse.data.authorized)
    } catch (e) {
      setIsAuthenticated(false)
    }
  }

  useEffect(() => {
    if (token) {
      setLoading(true)
      getAndSetAdminAuthorization()
      setLoading(false)
    } else {
      setIsAuthenticated(false)
      setLoading(false)
    }
  }, [token])

  // on first mount, pickup a token if there is one in localStorage
  useEffect(() => {
    setLoading(true)
    const savedLocalToken = window.localStorage.getItem('Token')
    if (savedLocalToken) {
      setToken(savedLocalToken)
    } else if (window.location.pathname !== '/login') {
      const nextUrl = window.location.pathname
      const loginUrl = nextUrl ? '/login/?next=' + nextUrl : '/login'
      history.replace(loginUrl)

      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [])

  const loginUser = async (email: string, password: string) => {
    const resp = await login({ email, password })
    const newToken = resp.data.key
    setToken(newToken)
    window.localStorage.setItem('Token', newToken)
  }

  const logoutUser = () => {
    window.localStorage.setItem('Token', '')
    setToken(undefined)
    setIsAuthenticated(undefined)
    history.push('/login')
  }

  return (
    <AuthContextProvider
      value={{
        isAuthenticated,

        loading,
        token,

        loginUser,
        logoutUser
      }}
    >
      {children}
    </AuthContextProvider>
  )
}

export { useAuth, AuthProvider }
