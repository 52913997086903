import React, { useEffect, useState } from 'react'

import { fetchPaginatedOrganizationsByName } from '../../api'
import { useAuth } from '../../context/auth-context'

import { IOrganization } from '../../types'
import {
  Box,
  Flex,
  HStack,
  Input,
  Select,
  Skeleton,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'

import { FilterableSelect } from '../../components/FilterableSelect'
import { ScrollableFlex } from '../../components/ScrollableFlex'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { TeamsListComponent } from './TeamsListComponent'

export interface TableDropdownOption {
  text: string
  testIdPrefix?: string
  variant: string
  onClick: (row: any) => void
}

interface Props {
  tenantId: number
}
const SKELETON_SIX_ROWS = [1, 2, 3, 4, 5, 6]
export const AdminDashboardTeams: React.FC<Props> = ({ tenantId }) => {
  const auth = useAuth()

  const [orgs, setOrgs] = useState<IOrganization[]>([])
  const [orgFilter, setOrgFilter] = useState<string>()
  const [total, setTotal] = useState(0)
  const [debouncedTerm, setDebouncedTerm] = useState('')
  const page_size = 10
  const [page, setPage] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFeching] = useState(false)

  const [planFilter, setPlanFilter] = useState<number>()

  // State to hide/show invite modal
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const [setUpModalIsVisible, setSetUpModalIsVisible] = useState(false)

  const fetchOrgs = (input_page: number, name: string | undefined) => {
    if (isFetching) return

    setIsFeching(true)
    return fetchPaginatedOrganizationsByName(
      tenantId,
      name,
      undefined,
      undefined,
      input_page,
      page_size
    )
      .then((res) => {
        if (res && res.data) {
          let results = res.data.results ?? []
          setOrgs(input_page > 1 ? orgs.concat(results) : results)
          setTotal(res.data.count ?? 0)
          setHasMoreResults(res.data.next != null)
          setPage(input_page + 1)
          setIsFeching(false)
        }
      })
      .catch((error) => {
        setIsFeching(false)
      })
  }

  useEffect(() => {
    if (orgFilter != undefined && !isFetching) {
      fetchOrgs(1, orgFilter)
    }
  }, [orgFilter, planFilter])

  useEffect(() => {
    const timer = setTimeout(() => setOrgFilter(debouncedTerm), 600)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  return (
    <Box
    // onBottomReached={() => {
    //   if (hasMoreResults && !isFetching && orgFilter != undefined) {
    //     fetchOrgs(page, orgFilter)
    //   }
    // }}
    >
      {/* Tables */}

      <Box bg="white" w={'100%'} shadow={4} rounded="md" px={8} py={5}>
        <VStack w="100%" alignItems="left">
          <Flex>
            <Text fontWeight="medium" fontSize={22}>
              Filter search
            </Text>
            {isFetching && <Spinner size="sm" />}
          </Flex>
          <HStack w="100%" spacing={3}>
            <Box w={['100%', '30%']}>
              <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                Name
              </Text>
              <HStack spacing={3}>
                <Input
                  bg={'contentBackgroundMain  '}
                  placeholder={'All teams'}
                  onChange={(e) => setDebouncedTerm(e.target.value)}
                />
              </HStack>
            </Box>
          </HStack>
        </VStack>
      </Box>

      <TeamsListComponent orgs={orgs} />
      {isFetching && (
        <VStack my={2} spacing={1}>
          {SKELETON_SIX_ROWS.map((i) => (
            <Skeleton key={i} w={'100%'} h={14} />
          ))}
        </VStack>
      )}
    </Box>
  )
}
