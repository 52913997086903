import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  FormControl,
  IconButton,
  Checkbox,
  Textarea,
  useToast
} from '@chakra-ui/react'
import React from 'react'
import { IPlatformTenant } from '../../../types'

import { Form, Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { updateAdminAIChatBotValues } from '../../../utils/api'
var regex = /^<iframe\b[^>]*>[^<]*<\/iframe>$/i
interface ISettingsItem {
  id?: string | number
  title: string
  description?: string
  direction: 'row' | 'column'
  children?: React.ReactNode
}

interface Props {
  platformTenant: IPlatformTenant
}

export const AIChatbotSettings: React.FC<Props> = (props) => {
  const { platformTenant } = props
  const toast = useToast()

  return (
    <Box>
      <Formik
        enableReinitialize={true}
        initialValues={{
          user_chatbot_iframe_html: platformTenant.user_chatbot_iframe_html,
          admin_chatbot_iframe_html: platformTenant.admin_chatbot_iframe_html,
          enable_admin_chatbot: platformTenant.enable_admin_chatbot ?? false,
          enable_user_chatbot: platformTenant.enable_user_chatbot ?? false
        }}
        validate={(values) => {
          const errors: any = {}
          if (
            values.admin_chatbot_iframe_html &&
            !regex.test(values.admin_chatbot_iframe_html)
          ) {
            errors['admin_chatbot_iframe_html'] = 'Invalid IFrame element.'
          }
          if (
            values.user_chatbot_iframe_html &&
            !regex.test(values.user_chatbot_iframe_html)
          ) {
            errors['user_chatbot_iframe_html'] = 'Invalid IFrame element.'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          updateAdminAIChatBotValues(platformTenant.id, values)
            .then((res) => {
              setSubmitting(false)
              toast({ title: 'Chatbot Config saved', status: 'success' })
            })
            .catch((err) => {
              setSubmitting(false)
              toast({
                title: 'There was an error',
                description: err,
                status: 'error'
              })
            })
        }}
      >
        {({ values, errors, setFieldValue, submitForm }) => (
          <Form>
            <Flex justifyContent="space-around">
              <SettingsItem
                id="1"
                title="Members AI Chatbot Config"
                description={
                  'Set an iframe to the trained AI Bot that members will see'
                }
                direction="column"
              >
                <Checkbox
                  size="sm"
                  isChecked={values.enable_user_chatbot}
                  onChange={(value) => {
                    setFieldValue('enable_user_chatbot', value.target.checked)
                  }}
                >
                  Enable Members AI Chatbot Functionality
                </Checkbox>
                <FormControl>
                  <Textarea
                    name="user_chatbot_iframe_html"
                    placeholder={"<iframe src='https://....'> </iframe>"}
                    value={values.user_chatbot_iframe_html}
                    onChange={(val) => {
                      setFieldValue(
                        'user_chatbot_iframe_html',
                        val.target.value
                      )
                    }}
                    minH={'300px'}
                    variant={'filled'}
                  />
                  {errors?.user_chatbot_iframe_html != undefined && (
                    <Text color="red" fontWeight="bold">
                      {errors?.user_chatbot_iframe_html}
                    </Text>
                  )}
                </FormControl>
              </SettingsItem>

              <SettingsItem
                id="1"
                title="Admins Bot Config"
                description={
                  'Set an iframe to the trained AI Bot that SpaceAdmins will see'
                }
                direction="column"
              >
                <Checkbox
                  size="sm"
                  isChecked={values.enable_admin_chatbot}
                  onChange={(value) => {
                    setFieldValue('enable_admin_chatbot', value.target.checked)
                  }}
                >
                  Enable Members AI Chatbot Functionality
                </Checkbox>
                <FormControl>
                  <Textarea
                    variant={'filled'}
                    name="admin_chatbot_iframe_html"
                    placeholder={"<iframe src='https://....'> </iframe>"}
                    value={values.admin_chatbot_iframe_html}
                    onChange={(val) => {
                      setFieldValue(
                        'admin_chatbot_iframe_html',
                        val.target.value
                      )
                    }}
                    minH={'300px'}
                  />
                  {errors?.admin_chatbot_iframe_html != undefined && (
                    <Text color="red" fontWeight="bold">
                      {errors?.admin_chatbot_iframe_html}
                    </Text>
                  )}
                </FormControl>
              </SettingsItem>
            </Flex>
            <Divider mt={5} mb={2} />
            <Flex flexDir="row" justifyContent="flex-end">
              <IconButton
                mr={2}
                size="xs"
                variant="solid"
                color={'white'}
                aria-label="Send email"
                icon={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => {
                  setFieldValue(
                    'admin_chatbot_iframe_html',
                    platformTenant.admin_chatbot_iframe_html
                  )
                }}
              />
              <Button
                size="xs"
                variant="solid"
                bg={platformTenant.brand_primary_color}
                textColor="#fff"
                onClick={submitForm}
              >
                Save
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

const SettingsItem: React.FunctionComponent<ISettingsItem> = (
  settings: ISettingsItem
) => {
  return (
    <Flex key={settings.id} direction={settings.direction}>
      <Box mb={2}>
        <Text
          fontSize={{ base: 'md' }}
          textAlign={'left'}
          maxW={'4xl'}
          fontWeight="medium"
        >
          {settings.title}
        </Text>
        <Text fontSize={{ base: 'xs' }}>{settings.description}</Text>
      </Box>
      <Spacer />
      <Box>{settings.children}</Box>
    </Flex>
  )
}
