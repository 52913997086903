import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Table, TableDataProp } from '../../components/table/Table'
import { IPlatformTenant } from '../../types'
import { useTenants } from '../../utils/apiHooks'
import { CreateTenantModal } from './CreateTenantModal'

export const Dashboard: React.FC = () => {
  const { data, isValidating } = useTenants()
  const [tableData, setTableData] = useState<any[]>([])
  const [showForm, setShowForm] = useState<boolean>(false)
  const renderLocationCell = (tenant: IPlatformTenant) => {
    return (
      <Popover>
        <PopoverTrigger>
          <Button variant="ghost">
            View {tenant.locations.length} Locations
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{tenant.locations.length} Locations</PopoverHeader>
          <PopoverBody>
            <VStack align="left">
              {tenant.locations.map((loc, i) => (
                <React.Fragment key={loc.id}>
                  <Text textAlign="left">{loc.name}</Text>
                  <Divider />
                </React.Fragment>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }

  const RenderTenantLink = (tenant: IPlatformTenant) => {
    return (
      <Link
        as={RouterLink}
        color="#255CE1"
        to={`/dashboard/tenant/${tenant.id}`}
      >
        {tenant.business_name}
      </Link>
    )
  }

  useEffect(() => {
    if (data) {
      setTableData(
        data.map((platformTenant) => {
          return {
            id: TableDataProp(platformTenant.id),

            business_name: TableDataProp(
              RenderTenantLink(platformTenant),
              platformTenant.business_name
            ),
            locations_count: TableDataProp(renderLocationCell(platformTenant)),
            total_members: TableDataProp(platformTenant.total_members),
            automatic_charge_threshold: TableDataProp(
              platformTenant.automatic_charge_threshold
                ? `$${platformTenant.automatic_charge_threshold}`
                : 'Not set'
            ),
            actions: TableDataProp('') //memberActions(platformTenant))
          }
        })
      )
    } else {
      setTableData([])
    }
  }, [data])

  // const memberActions = (rowData?: any) => [
  //   {
  //     text: 'Onboarding Done',

  //     testIdPrefix: rowData?.user?.email
  //       ? 'onboard-' + rowData.user.email
  //       : 'onboard',
  //     action: (rowId: number) => {
  //       const ouObj = orgUnits?.find((obj) => obj.id === rowId)
  //       if (ouObj) {
  //         props.handleOnboarding(ouObj.user.id)
  //       }
  //     }
  //   },
  //   {
  //     text: 'Create Connection',

  //     testIdPrefix: rowData?.user?.email
  //       ? 'connect-' + rowData.user.email
  //       : 'connect',
  //     action: (rowId: number) => {
  //       const ouObj = orgUnits?.find((obj) => obj.id === rowId)
  //       if (ouObj) {
  //         props.handleCreateConnection(ouObj)
  //       }
  //     }
  //   }
  // ]

  return (
    <Stack flex={1} pt="100px" align="center">
      <VStack spacing={4}>
        <Flex width="100%" justify="left">
          <Box alignContent="right">
            <Button
              colorScheme="teal"
              size="lg"
              onClick={() => setShowForm(!showForm)}
            >
              Invite a new Tenant
            </Button>
          </Box>
        </Flex>

        <Table
          headers={[
            { header: 'id', accessor: 'id', show: false },

            {
              header: 'Business Name',
              accessor: 'business_name',
              show: true,
              enableFilter: true
            },
            {
              header: 'Locations',
              accessor: 'locations_count',
              show: true
            },
            {
              header: 'Total members',
              accessor: 'total_members',
              show: true
            },
            {
              header: 'Minimum vaue to charge Automatically',
              accessor: 'automatic_charge_threshold',
              show: true
            },
            // {
            //   header: 'Plan',
            //   accessor: 'plan',
            //   show: true,

            //   enableFilter: true
            // },
            // {
            //   header: 'Location',
            //   accessor: 'location',
            //   show: true,

            //   enableFilter: true
            // },
            // {
            //   header: 'Start Date',
            //   accessor: 'start_date',
            //   show: true,

            //   minWidth: '74px'
            // },
            { header: '', accessor: 'actions', show: true, width: 0.5 / 12 }
          ]}
          data={tableData}
          loading={isValidating}
        />
      </VStack>

      <CreateTenantModal
        isOpen={showForm}
        onClose={() => setShowForm(false)}
        children={undefined}
      />
    </Stack>
  )
}
