import { Box, toast, useDisclosure, Wrap } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { removeContent } from '../../api'
import { useCustomToast } from '../../context/toast-context'
import { IDailyFiveContent } from '../../types'
import { usePublicContentPool, useTenants } from '../../utils/apiHooks'
import { BigDailyFive } from '../DailyFiveContentPool/BigDailyFive'
import { ContentModal } from '../DailyFiveContentPool/ContentModal'

export const DailyFive: React.FC = () => {
  const {} = useTenants()
  const [page, setPage] = useState<number>(1)
  const { data, error, revalidate } = usePublicContentPool(page)
  const [content, setContent] = useState<IDailyFiveContent[]>([])
  const { onOpen, isOpen, onClose } = useDisclosure()
  const [selectedPost, setSelectedPost] = useState<IDailyFiveContent>()
  const { newToast: toast } = useCustomToast()
  const [isFetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => {
    if (data && data.results) {
      setContent(content.concat(data.results))
      setIsFetching(false)
    }
  }, [data])

  useEffect(() => {
    if (isFetching) {
      setPage(page + 1)
    }
  }, [isFetching])

  return (
    <Box
      px={'40px'}
      overflowY="scroll"
      onScroll={(event) => {
        let element = event.target as HTMLElement
        if (
          element.scrollHeight - element.scrollTop <
            element.clientHeight * 1.4 &&
          !isFetching
        ) {
          setIsFetching(true)
        }
      }}
    >
      <Wrap spacing={6} paddingBottom="32px" align="bottom">
        {content &&
          content.map((c, i) => (
            <BigDailyFive
              w={['100%', '100%', '100%', '20%']}
              key={i}
              content={c}
              imageHeight="280px"
              onClick={() => {
                setSelectedPost(c)
                onOpen()
              }}
            />
          ))}
      </Wrap>
      <ContentModal
        isOpen={isOpen}
        content={selectedPost}
        onClose={onClose}
        onDelete={() => {
          if (!selectedPost) return
          removeContent(selectedPost?.id)
            .then((res) => {
              setContent(content.filter((c) => c.id != selectedPost?.id))
              toast({
                description: 'Post removed from circulation',
                status: 'success'
              })
              onClose()
            })
            .catch((err) => {
              toast({
                description: 'An error has occurred',
                status: 'error'
              })
            })
        }}
      />
    </Box>
  )
}
