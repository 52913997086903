import {
  Box,
  chakra,
  Flex,
  HStack,
  Link,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
export const Layout: React.FC = ({ children }) => {
  return (
    <Flex
      h="100vh"
      width="100vw"
      overflow="auto"
      justify="center"
      align="center"
    >
      <NavBar />
      <Flex
        mt="4.5rem"
        h="calc(100% - 4.5rem)"
        w="100%"
        overflow="auto"
        background="#FAFAFA"
      >
        {children}
      </Flex>
    </Flex>
  )
}

export const NavBar: React.FC = () => {
  return (
    <chakra.header
      w="full"
      h="4.5rem"
      pos="fixed"
      top="0"
      left="0"
      p={3}
      backgroundColor="#FAFAFA"
      borderBottom="2px"
      zIndex={1000}
    >
      <Flex w="100%" h="100%" flexDir="row" alignItems="center">
        <HStack>
          <Link as={RouterLink} to="/dashboard/">
            Dashboard
          </Link>
          <Link as={RouterLink} to="/dashboard/analytics">
            Analytics
          </Link>
          <Link as={RouterLink} to="/dashboard/daily-five">
            Daily Five Public Content
          </Link>
          <Link as={RouterLink} to="/dashboard/feature-flags">
            Feature Flags
          </Link>
        </HStack>
      </Flex>
    </chakra.header>
  )
}
