import { Text, Flex, Box } from '@chakra-ui/react'
import React from 'react'

export const TableHeader: React.FC<any> = (props) => {
  const { headerGroups, ...rest } = props

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      px="8px"
      // minWidth="750px"
      mb={2}
    >
      {headerGroups.map((headerGroup) =>
        headerGroup.headers.map((column, index) => {
          return (
            <Box
              key={index}
              width={column.width}
              minWidth={column.minWidth}
              fontSize="sm"
              color="grey"
              mb={0}
              textTransform="uppercase"
              fontWeight="semibold"
              {...column.getHeaderProps()}
            >
              {column.render('Header')}
              {column.canFilter ? column.render('Filter') : null}
            </Box>
          )
        })
      )}
    </Flex>
  )
}
