import { Link, Text, Box } from '@chakra-ui/react'
import React from 'react'

interface CellProps {
  value: LineValue
  width?: number
  minWidth?: number | string
}

interface LineValue {
  value: string | number | Element | JSX.Element
  link?: string
}

export const Cell: React.FC<CellProps> = props => {
  const { value, width, minWidth } = props

  const cellRender = () => {
    if (value.link) {
      return (
        <Link
          width={width}
          minWidth={minWidth}
          color="#255CE1"
          href={value.link}
          mb={0}
          // overflowX="ellipsis"
          display="nowrap"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {value.value}
        </Link>
      )
    } else if (typeof value.value === 'string') {
      return (
        <Text
          color="#757575"
          isTruncated
          width={width}
          minWidth={minWidth}
          mb={0}
          // overflowX="ellipsis"
          display="nowrap"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {value.value}
        </Text>
      )
    } else {
      return (
        <Box
          color="#757575"
          whiteSpace="nowrap"
          isTruncated
          width={width}
          minWidth={minWidth}
          mb={0}
        >
          {value.value}
        </Box>
      )
    }
  }

  return cellRender()
}
