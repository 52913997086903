import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import { AppProviders } from './AppProviders'
import { Layout } from './components/Layout'
import { useAuth } from './context/auth-context'
import { Analytics } from './pages/AdminDashboard/Analytics'
import { DailyFive } from './pages/AdminDashboard/DailyFive'
import { Dashboard } from './pages/AdminDashboard/Dashboard'
import { TenantDetails } from './pages/AdminDashboard/TenantDetails'
import { FeatureFlagToggle } from './pages/FeatureFlags/FeatureFlagToggle'
import { Login } from './pages/Login'
import { Logout } from './pages/Logout'
import { OrganizationDetail } from './pages/Organizations/OrganizationDetail'
import { TeamsListComponent } from './pages/Organizations/TeamsListComponent'

const UnauthenticatedGuard: React.FC = ({ children }) => {
  const auth = useAuth()

  if (auth.loading) {
    return null
  }
  if (auth.isAuthenticated) {
    // This guard was conflicting with the auth-context login method, this would redirect the user before the correct "next" url was applied.
    // using the same method to pickup on next should fix this
    return <Redirect to={'/dashboard'} />
  }
  return <>{children}</>
}
const LoginRoute = () => {
  return (
    <UnauthenticatedGuard>
      <Switch>
        <Route path={'/login'} render={() => <Login />} />
      </Switch>
    </UnauthenticatedGuard>
  )
}

const App: React.FC = () => {
  return (
    <AppProviders>
      <Flex
        flexDir="column"
        flexGrow={1}
        minH="100vh"
        overflow="hidden"
        mb={'100px'}
      >
        <Box>
          <Switch>
            <Route path={'/login'}>
              <LoginRoute />
            </Route>
            <Route exact path={'/logout'} component={Logout} />

            <Route exact path="/" render={() => <Redirect to={'/login'} />} />

            <Route path={'/dashboard'}>
              <Layout>
                <Switch>
                  <Route
                    exact
                    path={'/dashboard/analytics'}
                    component={Analytics}
                  />
                  <Route
                    exact
                    path={'/dashboard/daily-five'}
                    component={DailyFive}
                  />
                  <Route
                    exact
                    path={'/dashboard/feature-flags'}
                    component={FeatureFlagToggle}
                  />
                  <Route
                    path={'/dashboard/tenant/:tenant_id'}
                    component={TenantDetails}
                    exact={true}
                  />
                  <Route
                    path={'/dashboard/tenant/:tenant_id/:organization_id'}
                    component={OrganizationDetail}
                  />
                  <Route exact path={'/dashboard/'} component={Dashboard} />

                  <Redirect to="/dashboard/" />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </Box>
      </Flex>
    </AppProviders>
  )
}

export default App
