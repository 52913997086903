import {
  Box,
  Flex,
  FlexProps,
  Stack,
  Text,
  StackProps,
  Wrap,
  Spacer,
  Tag,
  TagLabel
} from '@chakra-ui/react'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router'
import comment from '../../assets/comment.svg'
import { IDailyFiveContent } from '../../types'
interface Props extends StackProps {
  content?: IDailyFiveContent
  imageHeight?: string
}

export const BigDailyFive: React.FC<Props> = ({
  content,
  imageHeight,
  ...rest
}) => {
  const history = useHistory()
  const [isShown, setIsShown] = useState(false)
  return (
    <Stack w="100%" align="space-between" {...rest}>
      <Box
        cursor="pointer"
        onClick={() => {}}
        w="100%"
        h={imageHeight ? imageHeight : '70%'}
        background={
          content && content.image ? `url("${content?.image}")` : '#dadada'
        }
        backgroundSize="cover"
        backgroundPosition="center"
      ></Box>

      <Box w="100%">
        <Stack
          h="100%"
          alignItems="space-between"
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <Stack>
            <Flex>
              <Text
                fontSize="xs"
                color="#255CE1"
                fontWeight="bold"
                margin="auto"
              >
                {content ? content.content_type : <Skeleton width={30} />}
              </Text>
              <Spacer />
            </Flex>
            <Text
              fontSize="s"
              color="#000000"
              fontWeight="bold"
              cursor="pointer"
              onClick={() => {}}
            >
              {content ? content.title : <Skeleton width={150} />}
            </Text>
          </Stack>
          <Wrap cursor={'pointer'} maxWidth={'inherit'}>
            {content &&
              content.interests.map((interest, i) => (
                <Tag
                  size={'sm'}
                  key={interest.id}
                  variant={'outline'}
                  colorScheme="blue"
                  cursor={'pointer'}
                  {...rest}
                >
                  <TagLabel>{interest.name}</TagLabel>
                </Tag>
              ))}
          </Wrap>
        </Stack>
      </Box>
    </Stack>
  )
}
