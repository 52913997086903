import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { routeStrings } from '../../routeStrings'
import { updateInvoiceChargeThreshold } from '../../utils/api'
import { useFeatureFlagsAPI, useTenantInfo } from '../../utils/apiHooks'
import { AdminDashboardTeams } from '../Organizations/AdminDashboardTeams'
import { AIChatbotSettings } from './TenantSettings/AIChatbotSettings'

export const TenantDetails: React.FC = () => {
  const history = useHistory()
  const { tenant_id } = useParams<any>()
  const { data: flags, revalidate: revalidateFeatureFlags } =
    useFeatureFlagsAPI()

  const toast = useToast()

  const { data: tenant, revalidate } = useTenantInfo(Number.parseInt(tenant_id))

  const { isOpen: isEdition, onToggle: toggleEdition } = useDisclosure()

  const [threshold, setThreshold] = useState<number | undefined>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enabledThresholdCharge, setEnabledThresholdCharge] =
    useState<boolean>()

  useEffect(() => {
    if (tenant) {
      setThreshold(tenant.automatic_charge_threshold)
    }
  }, [tenant])

  useEffect(() => {
    if (flags) {
      let thresholdFlag = flags.find(
        (f) => f.name === 'automatic_charge_threshold'
      )
      if (thresholdFlag) setEnabledThresholdCharge(thresholdFlag.enabled)
    }
  }, [flags])

  const updateThreshold = () => {
    if (isSubmitting || !tenant) return

    if (threshold && threshold < 0) {
      toast({ status: 'error', title: 'Value cannot be negative' })
      return
    }

    setIsSubmitting(true)
    updateInvoiceChargeThreshold(tenant.id, threshold ?? 0)
      .then((res) => {
        toast({ title: res.data })
        toggleEdition()
        revalidate()
        setIsSubmitting(false)
      })
      .catch((err) => {
        setIsSubmitting(false)
        toast({ status: 'error', title: 'An error ocurred' })
      })
  }

  const onSelectOrg = (orgId: number) => {}
  if (!tenant) return null

  return (
    <AdminBodyWrapper>
      <Container
        maxW={'6.xl'}
        py={12}
        px={5}
        background={'white'}
        mt={1}
        mb={4}
        borderRadius="lg"
      >
        <Flex flexDirection={['column', 'column', 'row']}>
          <IconButton
            mt={3}
            variant="solid"
            bg={tenant.brand_primary_color}
            color={'white'}
            aria-label="Send email"
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => {
              history.replace(routeStrings.home)
            }}
            mr={8}
          />
          <Box mb={2}>
            <Text fontSize={['md', 'lg', '3xl']} fontWeight="bold">
              {tenant.business_name}
            </Text>
            <Text
              color={'gray.500'}
              fontSize={['sm', 'lg']}
              fontWeight={'normal'}
            >
              {tenant.abn}
            </Text>
          </Box>
          <Spacer />
          <Box>
            <Image src={tenant?.logo_long} height={['5', '8', '10']} />
          </Box>
        </Flex>
        <Divider my="3" />
        <Heading size={'md'} py={2}>
          Settings
        </Heading>
        <Box w={'100%'}>
          <Stack>
            <Box p={1}>
              <Stack w={'60%'} spacing={2}>
                <Text fontSize="md" fontWeight="bold">
                  Charge invoices automatically
                </Text>
                <Text fontSize="md">
                  Set a minimum value to decide whether to charge the invoice
                  generated right after a member has signed up
                </Text>
                {enabledThresholdCharge ? (
                  isEdition ? (
                    <Flex width={'80%'}>
                      <Input
                        textAlign={'right'}
                        mr={5}
                        type="text"
                        value={threshold}
                        onChange={(e) => {
                          let value = Number(e.target.value)
                          setThreshold(isNaN(value) ? undefined : value)
                        }}
                      />
                      <Button
                        color={'white'}
                        background={tenant?.brand_primary_color}
                        isLoading={isSubmitting}
                        onClick={updateThreshold}
                      >
                        Save
                      </Button>
                      <Button
                        isDisabled={isSubmitting}
                        colorScheme={'gray'}
                        ml={2}
                        onClick={() => {
                          setThreshold(tenant?.automatic_charge_threshold)
                          toggleEdition()
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex alignItems="right">
                      <Text w="90%" fontWeight={'bold'} textAlign="right" p={2}>
                        {tenant?.automatic_charge_threshold
                          ? `$${tenant.automatic_charge_threshold}`
                          : 'Value not set'}{' '}
                      </Text>
                      <Button
                        ml={2}
                        colorScheme="gray"
                        onClick={() => {
                          toggleEdition()
                        }}
                      >
                        Change Value
                      </Button>
                    </Flex>
                  )
                ) : (
                  <Text fontWeight="bold">This feature is not enabled</Text>
                )}
              </Stack>
              <Divider my={18} />
              <Stack w={'100%'} spacing={2}>
                <Text fontSize="md" fontWeight="bold">
                  AI Chatbots
                </Text>
                <Text fontSize="sm">
                  Set up the configuration for the Members and Admins' Chatbot
                  for this Space Tenant.
                </Text>
                <AIChatbotSettings platformTenant={tenant} />
              </Stack>
            </Box>
            <Flex p={2} mt={5}></Flex>
          </Stack>
        </Box>
        <Divider my={5} />
        <Heading size={'md'} py={2}>
          Teams
        </Heading>

        <AdminDashboardTeams tenantId={tenant.id} />
      </Container>
    </AdminBodyWrapper>
  )
}
