import { Stack, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'

interface StackCellProps {
  values: LineValue[]
  width: number
  minWidth: number | string
}

interface LineValue {
  value: string
  link?: string
  header?: boolean
}

export const StackCell: React.FC<StackCellProps> = props => {
  const HeaderStyle = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    p {
      margin-bottom: 0px;
    }
  `

  const SubStyle = styled(Text)`
    color: #757575;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
  `

  const values = props.values
  const width = props.width

  return (
    <Stack width={width} overflow="hidden" display="inline" spacing={0}>
      {values.map((value, index) => {
        let render
        if (value.link) {
          render = (
            <Link key={index} color="#255CE1" to={value.link}>
              {value.value}
            </Link>
          )
        } else if (value.header) {
          render = <HeaderStyle key={index}>{value.value}</HeaderStyle>
        } else {
          render = <SubStyle key={index}>{value.value}</SubStyle>
        }

        return render
      })}
    </Stack>
  )
}
