export const routeStrings = {
  home: '/',
  login: '/login',
  logout: '/logout',
  location: '/start/location',
  plan: '/start/plan',
  notFound: '/not-found',
  tenantDetailView: (tenantId: number) => `/dashboard/tenant/${tenantId}`,
  teamDetailView: (tenantId: number, orgId: number) => `/dashboard/tenant/${tenantId}/${orgId}`,
}
