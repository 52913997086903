import React from 'react'
import { Flex, useTheme } from '@chakra-ui/react'
import { useMedia } from '../hooks/useMedia'

export const useAdminBodyWidth = () =>
  useMedia(['(min-width: 1346px)'], ['1140px'], '100%')

export const AdminBodyWrapper: React.FC<any> = (props) => {
  const { disabled, onScroll, ...rest } = props

  const width = useAdminBodyWidth()

  return (
    <Flex
      width="100%"
      bg="contentBackgroundMain"
      justify="center"
      align="start"
      overflowY="scroll"
      overflowX="auto"
      data-testid="admin-body-wrapper"
      pb={16}
      flex={1}
      onScroll={(event) => {
        try {
          onScroll && onScroll(event)
        } catch (error) {
          console.log('Error scrolling')
          console.error(error)
        }
      }}
    >
      <Flex
        width={width}
        justify="start"
        direction="column"
        alignSelf="bottom"
        alignItems="bottom"
        mt={10}
        mx={3}
        {...rest}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}
