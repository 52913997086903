import { ChakraProvider } from '@chakra-ui/react'
import { createBrowserHistory } from 'history'
import React from 'react'
import { Router } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { request } from './api'
import { AuthProvider } from './context/auth-context'
import { ToastProvider } from './context/toast-context'
import { initErrorTracking } from './utils/errorTracking'

const history = createBrowserHistory()

initErrorTracking(history)

// onboarding screens -> spacecubed
// member dashboard screens -> specified coworking space

export const AppProviders: React.FC = ({ children }) => {
  return (
    <SWRConfig
      value={{
        fetcher: (url: string) =>
          request<any>(url, 'GET').then((res) => res.data)
      }}
    >
      <ChakraProvider>
        <Router history={history}>
          <AuthProvider>
            <ToastProvider>{children}</ToastProvider>
          </AuthProvider>
        </Router>
      </ChakraProvider>
    </SWRConfig>
  )
}
