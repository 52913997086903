import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import {
  filterOrganizationQueryParams,
  IFeatureFlag,
  IOrganization,
  IOrganizationDetail,
  IPaginatedQuery,
  IPlatformTenant,
  NewTenant
} from './types'

import { encodeQueryParams } from 'serialize-query-params'
import { stringify } from 'query-string'
import useSWR from 'swr'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST
axios.defaults.headers.post['Content-Type'] = 'application/json'

/**
 * a wrapper around Axios.request to make API calls using the Django token
 * @param url the URL to make the request to - only the path eg ('/invoices'), not the full URL
 * @param method the HTTP method to use
 */
export const request = <T>(
  url: string,
  method: Method,
  config?: Partial<AxiosRequestConfig>
): Promise<AxiosResponse<T>> => {
  const token = window.localStorage.getItem('Token')
  const headers = token && { Authorization: `Token ${token}` }

  return axios.request<T>({
    url,
    method,
    headers,
    ...config
  })
}

export const login = ({
  email,
  password
}: {
  email: string
  password: string
}) =>
  axios.post<{ key: string }>('/api/v1/rest-auth/login/', {
    username: email,
    password
  })

export const setUpNewTenant = (values: NewTenant) => {
  return request('/api/v1/platform-admin/tenants/', 'POST', {
    data: values
  })
}

export const getAuthorization = () =>
  request<{ authorized: boolean }>('/api/v1/platform-admin/authorize/', 'get')

export const removeContent = (contentId: number) => {
  return request(
    `/api/v1/platform-admin/content/${contentId}/remove_content/`,
    'POST'
  ) as Promise<AxiosResponse<string>>
}

export const setFeatureFlag = (flag: IFeatureFlag) =>
  request(`/api/v1/platform-admin/feature-flags/${flag.id}/`, 'PUT', {
    data: flag
  })

// Paginated, filterable requests
export const fetchPaginatedOrganizationsByName = (
  tenantId: number,
  name?: string,
  location?: string,
  plan?: number,
  page?: number,
  page_size?: number
) => {
  const params = encodeQueryParams(filterOrganizationQueryParams, {
    page: page ?? 1,
    tenant: tenantId,
    name,
    location,
    plan,
    page_size
  })

  return request<IPaginatedQuery<IOrganization>>(
    `/api/v1/organizations/?${stringify(params)}`,
    'GET'
  )
}

export const deactivateTeam = (organizationId: number) => {
  return request(
    `/api/v1/platform-admin/organizations/${organizationId}/deactivate_team/`,
    'POST'
  )
}

export function useOrganization(id: number | undefined) {
  return useSWR<IOrganizationDetail>(
    id ? `/api/v1/platform-admin/organizations/${id}/` : null
  )
}
