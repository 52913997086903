import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { setUpNewTenant } from '../../api'
import { InputWarning } from '../../components/InputWarning'
import { StyledInput } from '../../components/StyledInputs'
import { useCustomToast } from '../../context/toast-context'
import { NewTenant } from '../../types'
import { EMAIL_REGEX } from '../../utils/constants'

interface Props extends ModalProps {}

export const CreateTenantModal: React.FC<Props> = ({ ...rest }) => {
  const { newToast: toast } = useCustomToast()

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px" p="25px">
        <ModalHeader pb={5}>Create Tenant</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            name: '',
            email: ''
          }}
          validate={(values: NewTenant) => {
            const errors: any = {}
            if (!values.name) {
              errors.name = 'Tenant name is required'
            }
            if (!values.email) {
              errors.email = 'Email is required'
            } else if (!EMAIL_REGEX.test(values.email)) {
              errors.email = 'Invalid email address'
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            setUpNewTenant(values)
              .then((data) => {
                setSubmitting(false)
                rest.onClose()
                toast({ description: 'Invitation sent', status: 'success' })
              })
              .catch((error) => {
                setSubmitting(false)
                console.log(error)
              })
          }}
        >
          {({ values, setFieldValue, isSubmitting, touched, errors }) => (
            <Form>
              <FormControl isRequired>
                <FormLabel>Tenant Name</FormLabel>
                <StyledInput
                  placeholder="Tenant Name"
                  value={values.name || ''}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value)
                  }}
                  w="100%"
                ></StyledInput>
                <ErrorMessage name="name" component={InputWarning} />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <StyledInput
                  type="email"
                  placeholder="Email"
                  value={values.email || ''}
                  onChange={(e) => {
                    setFieldValue('email', e.target.value)
                  }}
                  w="100%"
                ></StyledInput>
                <ErrorMessage name="email" component={InputWarning} />
              </FormControl>
              <Button onClick={rest.onClose}>Cancel</Button>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
