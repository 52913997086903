import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { DotsIcon } from '../icons/icons'

export interface IRowDataTypes {
  rowData: number | string
}

export interface IAction {
  text: string
  testIdPrefix: string
  action: (rowId: number) => void
}

export const ActionsDropdown: React.FC<any> = ({
  actions,
  dataTestid,
  rowId,
  rowData,
  width,
  ...rest
}: {
  actions: IAction[]
  dataTestid: string
  rowId: number
  rowData?: IRowDataTypes
  width: number
}) => {
  return (
    <Box width={width} textAlign="right">
      {actions.length > 0 && (
        <Menu placement="left-start">
          <MenuButton
            as={Button}
            aria-label="Actions"
            bg="#fff"
            data-testid={dataTestid}
          >
            <DotsIcon />
          </MenuButton>
          <MenuList zIndex={100}>
            {!!actions &&
              actions.map((action: IAction, index) => {
                return (
                  <MenuItem
                    onClick={() => action.action(rowId)}
                    key={`${rowId}-${index}`}
                    data-testid={`${action.testIdPrefix}`}
                  >
                    <Text>{action.text}</Text>
                  </MenuItem>
                )
              })}
          </MenuList>
        </Menu>
      )}
    </Box>
  )
}
