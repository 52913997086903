import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { IPlatformTenant } from '../types'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST
axios.defaults.headers.post['Content-Type'] = 'application/json'

/**
 * a wrapper around Axios.request to make API calls using the Django token
 * @param url the URL to make the request to - only the path eg ('/invoices'), not the full URL
 * @param method the HTTP method to use
 */
export const request = <T>(
  url: string,
  method: Method,
  config?: Partial<AxiosRequestConfig>
): Promise<AxiosResponse<T>> => {
  const token = window.localStorage.getItem('Token')
  const headers = token && { Authorization: `Token ${token}` }

  return axios.request<T>({
    url,
    method,
    headers,
    ...config
  })
}

export const updateInvoiceChargeThreshold = (
  tenant_id: number,
  value: number
) =>
  request<string>(
    `api/v1/platform-admin/tenants/${tenant_id}/update_automatic_charge_threshold/`,
    'POST',
    {
      data: { automatic_charge_threshold: value }
    }
  )

export const updateAdminAIChatBotValues = (
  tenant_id: number,
  data: {
    enable_user_chatbot?: boolean
    user_chatbot_iframe_html?: string
    enable_admin_chatbot?: boolean
    admin_chatbot_iframe_html?: string
  }
) =>
  request<string>(
    `api/v1/platform-admin/tenants/${tenant_id}/update_ai_chatbot_values/`,
    'POST',
    {
      data
    }
  )
