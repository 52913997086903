import { BoxProps, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { IDailyFiveContent } from '../../types'
import { connect } from 'http2'
import { TimeIcon, ExternalLinkIcon, TriangleDownIcon, LinkIcon } from "@chakra-ui/icons"
interface DetailProps extends BoxProps {
  content: IDailyFiveContent | undefined
}
export const ContentDetailBar: React.SFC<DetailProps> = ({ content }) => {
  const showDate = content?.date || content?.start_time || content?.finish_time

  return (
    <Flex flexDirection={['column']}>
      <Flex flexDirection={['column', 'row']} mb={['20px']}>
        {showDate && (
          <Flex alignContent="top" mr={[0, '50px']}>
            <TimeIcon marginRight="20px" />
            <Stack spacing="4px">
              <Text color="#333333" fontSize="s" fontWeight="bold">
                {content?.date
                  ? format(Date.parse(content.date!), 'E, dd/MM/yyyy')
                  : 'Time'}
              </Text>
              <Text color="#333333" fontSize="s">
                {`${content?.start_time
                  ? format(
                    Date.parse(`2020-04-23T${content?.start_time}`),
                    'h:mm aa'
                  )
                  : ''
                  } - ${content?.finish_time
                    ? format(
                      Date.parse(`2020-04-23T${content?.finish_time}`),
                      'h:mm	aa'
                    )
                    : ''
                  }`}
              </Text>
            </Stack>
          </Flex>
        )}

        {content?.location && (
          <Flex alignContent="top" mr={[0, '50px']}>
            <TriangleDownIcon marginRight="20px" />
            <Stack spacing="4px">
              <Text color="#333333" fontSize="s" fontWeight="bold">
                Location
              </Text>
              <Text color="#333333" fontSize="s">
                {content.location}
              </Text>
            </Stack>
          </Flex>
        )}
      </Flex>
      <Flex flexDirection={['column', 'row']} mb={['20px']}>
        {content?.zoom_meeting_url && (
          <Flex alignContent="top" mr={[0, '50px']}>
            <ExternalLinkIcon marginRight="20px" />
            <Stack spacing="4px">
              <Link
                href={content.zoom_meeting_url}
                color="#2F80ED"
                fontSize="s"
                fontWeight="bold"
              >
                Join Zoom Meeting
              </Link>
              <Text color="#333333" fontSize="s">
                Password: {content.zoom_meeting_password}
              </Text>
            </Stack>
          </Flex>
        )}
        {content?.url && (
          <Flex alignContent="top" mr={[0, '50px']}>
            <LinkIcon marginRight="20px" />
            <Link
              isExternal
              href={
                content.url.startsWith('http') ||
                  content.url.startsWith('mailto:')
                  ? content.url
                  : '//' + content.url
              }
              color="#2F80ED"
              fontSize="s"
              fontWeight="bold"
            >
              Visit link
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
